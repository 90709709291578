<template>
  <div v-loading="isLoading" element-loading-background="rgba(255, 255, 255, 0.6)">
    <div class="print-content m-auto" v-show="dataLoaded">
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column justify-content-between">
          <div class="d-flex">
            <div class="section-header-logo" :style="'border-color:'+envTenantInvTopColor">
              <img :src="envTenantInvTopLogoUrl" alt="Logo">
            </div>
            <div class="section-header-social ml-2" :style="'color:'+envTenantInvTopColor">
              <p class="text-bigger text-uppercase"><strong>{{envTenantName}}</strong></p>
              <p class="text-uppercase">{{envTenantTagline}}</p>
              <i class="fa fa-whatsapp"></i> {{envTenantPhone}}<br/>
              <i class="fa fa-instagram"></i> @{{envTenantInstagram}}
            </div>
          </div>
          <div class="section-header-body mt-3">
            <p class="text-small">Petugas Kasir: {{model.createdByFullName}}</p>
            <p class="text-small">Metode Pembayaran: {{model.activePayment.paymentMethodLocal}}</p>
            <p class="text-small" v-if="showCustomer">Nama Pelanggan: {{model.customerName}}</p>
          </div>
        </div>
        <div>
          <div class="text-right section-header-body">
            <p v-for="(address, index) in envTenantAddresses" :key="index" class="text-small" :style="'color:'+envTenantInvTopColor">
              <strong v-if="address.addressLine1">{{address.addressLine1}}<br/></strong>
              <strong v-if="address.addressLine2">{{address.addressLine2}}<br/></strong>
              <i v-if="address.phone" class="fa fa-phone"></i> {{address.phone}}
            </p>
          </div>
        </div>
      </div>

      <div class="mt-3 section-header-content">
        <div class="d-flex justify-content-between">
          <div class="text-uppercase"><strong class="text-success">PEMBELIAN</strong>&nbsp;&nbsp;|&nbsp;&nbsp;{{model.transactionNo}}</div>
          <div class="text-uppercase">{{model.createdAt | dateTimeFormat}}&nbsp;&nbsp;|&nbsp;&nbsp;<strong :class="getStatusTextColor(model.status)">{{model.statusLocal}}</strong></div>
        </div>
      </div>

      <div>
        <el-table :data="model.items" 
                  header-row-class-name="text-primary"
                  class="table-compact no-x-padding">
          <el-table-column label="No." width="50" class-name="pl-0">
            <template slot-scope="scope">
              <div>{{scope.$index + 1}}</div>
            </template>
          </el-table-column>
          <el-table-column label="Nama Barang" min-width="150">
            <template slot-scope="scope">
              <div>
                <div>{{scope.row.productCode}}</div>
                <div>{{scope.row.productName}}</div>
                <div v-if="scope.row.productSize" class="text-small">Ukuran {{scope.row.productSize}}</div>
                <div class="text-small">{{scope.row.note}}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Kadar" width="140">
            <template slot-scope="scope">
              <div>{{scope.row.productMaterialName}}<br/>{{scope.row.productCaratPercentage}}%</div>
            </template>
          </el-table-column>
          <el-table-column label="Berat" align="right" width="110">
            <template slot-scope="scope">
              <div>{{scope.row.productWeight}}gr</div>
            </template>
          </el-table-column>
          <el-table-column label="Satuan" align="right" width="150">
            <template slot-scope="scope">
              <div>{{scope.row.displayUnitPrice | currencyFormat}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="quantity" label="Qty" align="right" width="75"></el-table-column>
          <el-table-column label="Jumlah" align="right" class-name="pr-0" width="150">
            <template slot-scope="scope">
              <div>{{(model.taxInclusive ? scope.row.totalAmount : scope.row.subTotalAmount) | currencyFormat}}</div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="row mt-2">
        <div class="col-7">
          <div class="py-1 text-small">
            <p>Catatan:</p>
            <ol>
              <li v-if="itemsHasTax && model.taxInclusive">Harga sudah termasuk pajak.</li>
              <li>Jika karat dan berat tidak cocok, diberikan waktu 1 hari untuk mengembalikan barang sesuai dengan harga di nota dengan membawa surat dari tempat uji.</li>
              <li>Jika barang akan dijual kembali, harap membawa nota ini dan akan dibayarkan menurut harga pasaran jual beli saat itu.</li>
            </ol> 
            <p v-if="model.taxGroups.length && !itemsHaveSameTax && !model.taxInclusive">
              *&nbsp;&nbsp;Pajak untuk produk tertentu
            </p>
          </div>
        </div>
        <div class="col-5">
          <div class="row py-1" v-if="model.taxGroups.length && !model.taxInclusive">
            <div class="col-6">Subtotal</div>
            <div class="col-6 text-right">{{(model.subTotalAmount || 0) | currencyFormat}}</div>
          </div>

          <div v-if="!model.taxInclusive">
            <div v-for="(taxGroup, index) in model.taxGroups" :key="index" class="row py-1">
              <div class="col-6">{{taxGroup.name}}<span v-if="!itemsHaveSameTax">*</span></div>
              <div class="col-6 text-right">{{taxGroup.amount | currencyFormat}}</div>
            </div>
          </div>

          <div class="row py-1" v-if="model.taxGroups.length && !model.taxInclusive">
            <div class="col-12">
              <div class="border-top-dark"></div>
            </div>
          </div>

          <div class="row py-1">
            <div class="col-6 text-uppercase"><strong>Total</strong></div>
            <div class="col-6 text-right"><strong>{{(model.totalAmount || 0) | currencyFormat}}</strong></div>
          </div>

          <div class="row py-1">
            <div class="col-6">Bayar</div>
            <div class="col-6 text-right">{{(model.activePayment.payAmount || 0) | currencyFormat}}</div>
          </div>

          <div class="row py-1">
            <div class="col-6">Kembali</div>
            <div class="col-6 text-right">{{(model.activePayment.changeAmount || 0) | currencyFormat}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import base64url from 'base64url'
  import { Table, TableColumn, Loading } from 'element-ui'
  import filterHelper from '../../../helpers/filterHelper'
  import * as urlConstant from '../../../constants/urlConstant'
  import * as sellInvoicePaymentStatusEnum from '../../../enums/sellInvoicePaymentStatusEnum'
  import * as paymentMethodEnum from '../../../enums/paymentMethodEnum'
  import * as sellInvoiceStatusEnum from '../../../enums/sellInvoiceStatusEnum'
  import * as customerConstant from '../../../constants/customerConstant'

  Vue.use(Loading)

  export default {
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
    },
    data () {
      return {
        dataLoaded: false,
        isInvoiceLoading: false,
        model: {
          id: null,
          customerCode: '',
          customerName: '',
          transactionNo: '',
          externalNote: '',
          subTotalAmount: null,
          taxGroups: [],
          totalAmount: null,
          items: [],
          createdAt: null,
          createdByFullName: '',
          status: '',
          statusLocal: '',
          payments: [],
          activePayment: {},
          taxInclusive: true
        },
      }
    },
    created () {
      this.init()
    },
    methods: {
      init () {
        const invoiceId = base64url.decode(this.$route.params.invoiceSlug)

        this.isInvoiceLoading = true
        this.axios.get(`${urlConstant.API_URL_INVOICES}/${invoiceId}`)
          .then(response => {
            if (!response.data.isSuccess || !response.data.invoice) {
              this.isInvoiceLoading = false
              return
            }
            
            this.model = _.cloneDeep(Object.assign(this.model, response.data.invoice))

            // Get the last active payment
            const paidPayments = this.model.payments && this.model.payments.length ?
              this.model.payments.filter(p => p.status === sellInvoicePaymentStatusEnum.PAID) : []
            this.model.activePayment = paidPayments.length ? paidPayments[paidPayments.length - 1] : null

            if (!this.model.activePayment) {
              return
            }

            this.model.activePayment.paymentMethodLocal = paymentMethodEnum.getLocal(this.model.activePayment.paymentMethod)
            this.model.statusLocal = sellInvoiceStatusEnum.getLocal(this.model.status)

            for (const item of this.model.items) {
              // Update the display unit price
              let unitTaxAmount = item.taxAmount / item.quantity
              if (this.model.taxInclusive) {
                item.displayUnitPrice = item.unitPrice + unitTaxAmount
              }
              else {
                item.displayUnitPrice = item.unitPrice
              }
            }

            this.updateTaxGroups()
            
            this.isInvoiceLoading = false
            this.dataLoaded = true

            this.$nextTick(() => {
              setTimeout(() => {
                this.printWhenDomReady()
              }, 750)
            })
          })
          .catch(() => {
            this.isInvoiceLoading = false
          })
      },
      updateTaxGroups () {
        const allTaxes = this.model.items
          .reduce((taxes, item) => {
            taxes.push(...item.taxes)
            return taxes
          }, [])
          .filter(tax => tax.taxItemId)

        const group = _.groupBy(allTaxes, tax => tax.taxItemId)
        for (const key of Object.keys(group)) {
          const taxGroup = group[key]
          const totalTaxAmount = taxGroup.reduce((val, tax) => val + tax.amount, 0)

          this.model.taxGroups.push({
            name: taxGroup[0].taxItemName,
            amount: totalTaxAmount,
            count: taxGroup.length
          })
        }
      },
      getStatusTextColor (invoiceStatus) {
        switch (invoiceStatus) {
          case sellInvoiceStatusEnum.OPEN:
            return 'text-warning'
          case sellInvoiceStatusEnum.CLOSED:
            return 'text-muted'
          case sellInvoiceStatusEnum.PAID:
            return 'text-success'
          case sellInvoiceStatusEnum.VOIDED:
            return 'text-danger'
          default:
            return 'text-muted'
        }
      },
      printWhenDomReady () {
        if (document.readyState === 'complete') {
          window.print()
          return
        }

        window.addEventListener('load', () => {
          window.print()
        })
      },
    },
    computed: {
      itemsHasTax () {
        return this.model.taxGroups.length > 0
      },
      itemsHaveSameTax () {
        const items = this.model.items.filter(i => !!i.id)
        return this.model.taxGroups.length === 1 && 
               this.model.taxGroups[0].count === items.length
      },
      showCustomer () {
        return this.model.customerCode !== customerConstant.DEFAULT_CUSTOMER_CODE
      },
      isLoading () {
        return this.isInvoiceLoading
      },
      envTenantName() {
        // eslint-disable-next-line no-undef
        return process.env.VUE_APP_TENANT_NAME_PUBLIC
      },
      envTenantTagline() {
        // eslint-disable-next-line no-undef
        return process.env.VUE_APP_TENANT_TAGLINE
      },
      envTenantPhone() {
        // eslint-disable-next-line no-undef
        return process.env.VUE_APP_TENANT_PHONE
      },
      envTenantInstagram() {
        // eslint-disable-next-line no-undef
        return process.env.VUE_APP_TENANT_INSTAGRAM
      },
      envTenantAddresses() {
        // eslint-disable-next-line no-undef
        return JSON.parse(process.env.VUE_APP_TENANT_ADDRESSES)
      },
      envTenantInvTopColor() {
        // eslint-disable-next-line no-undef
        return '#' + process.env.VUE_APP_TENANT_INVOICE_TOP_COLOR
      },
      envTenantInvTopLogoUrl() {
        // eslint-disable-next-line no-undef
        return process.env.VUE_APP_TENANT_INVOICE_TOP_LOGO
      },
    },
    filters: {
      ...filterHelper.spread
    },
  }
</script>
<style scoped lang="scss">
  .print-content {
    width: 315mm; // 210mm + 50%
    padding: 20mm;
    font-size: 16px;
    line-height: 1.5em;
    color: black;

    .section-header-content {
      padding: 8px 0;
      border: 0 solid black;
      border-width: 1px 0;
    }
    .el-table {
      font-size: 16px;
      color: black;
      
      ::v-deep {
        table {
          > thead > tr > th {
            font-size: 16px;

            &.is-leaf {
              border-bottom-color: lightgray;
            }
          }

          > tbody > tr {
            &:last-child {
              > td {
                border-bottom-color: black;
              }
            }

            > td {
              padding-top: 8px;
              padding-bottom: 8px;
              border-bottom-color: lightgray;
            }
          }
        }

        .cell {
          line-height: 1.5em;
        }
      }
      &::before {
        background-color: transparent;
      }
    }
    .section-header-logo {
      border: 4px double black;
      width: 80px;
      height: 80px;
      border-radius: 8px;
    }
    .section-header-social {
      line-height: 1.3em;
    }
    .section-header-body {
      line-height: 1.1em;
    }
    .text-big {
      font-size: 18px;
    }
    .text-bigger {
      font-size: 24px;
    }
    .text-small {
      font-size: 14px;
    }
    .border-top-dark {
      border-top: 1px solid black;
    }

    p {
      margin: 0;
    }
    ol {
      margin: 0;
      padding-inline-start: 1em;
    }
  }
</style>
